// Production Only
//  export const API_URL = "https://backend.sendmeatrainer.com/api/v1"

// Local Development Only
// export const API_URL = "http://localhost:4000/api/v1"
// export const API_URL = "http://192.168.29.29:4000/api/v1"

// Staging
//  export const API_URL = 'http://3.238.229.142:8002/api/v1';
 export const API_URL = 'https://backend-stag.sendmeatrainer.com/api/v1';
